import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TickGlobalModal from "./TickGlobalModal";
import styles from "./modal.module.scss";
import successIcon from "../../assets/icons/modalSuccessIcon.svg";

const TickSuccessModal = () => {
  const tickGlobalModalState = useSelector((state) => state.tickModal);

  useEffect(() => {
    return () => {
      tickGlobalModalState.params.callback &&
        tickGlobalModalState.params.callback();
    };
  }, []);

  return (
    <TickGlobalModal autoClose={1000}>
      <div className={styles.successContainer}>
        <img src={successIcon} alt="success-icon" className={styles.icon} />
        <div className={styles.text}>{tickGlobalModalState?.params?.msg}</div>
      </div>
    </TickGlobalModal>
  );
};

export default TickSuccessModal;
